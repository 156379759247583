<template>
    <div class="relative">
        <div class="area-code-dropdown no-select"
             :style="[dropdownValue == 'none' ? {'color': '#b4b8cb'} : {'color': '#0f1d29'}]"
             @click="clickHandler"
             v-on-clickaway="hideDropdown"
             :class="{'disabled': disabled, 'invalid': invalid}">
            <!-- <slot></slot> -->
            <div :class="['selected-flag', dropdownValue]"></div>
        </div>
        <transition name="fade-slide">
            <div class="area-code-dropdown-wrapper" v-show="showDropdown">
                <ul class="dropdown no-select">
                    <!-- Australia -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+61', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('AU')}">
                        <div class="flag au"></div>
                        Australia (+61)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Chile -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+56', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('CL')}">
                        <div class="flag cl"></div>
                        Chile (+56)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Czech Republic -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+420', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('CZ')}">
                        <div class="flag cz"></div>
                        Czech Republic (+420)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Estonia -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+372', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('EE')}">
                        <div class="flag ee"></div>
                        Estonia (+372)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Hong Kong -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+852', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('HK')}">
                        <div class="flag hk"></div>
                        Hong Kong (+852)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Hungary -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+36', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('HU')}">
                        <div class="flag hu"></div>
                        Hungary (+36)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Israel -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+972', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('IL')}">
                        <div class="flag il"></div>
                        Israel (+972)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Italy -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+39', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('IT')}">
                        <div class="flag it"></div>
                        Italy (+39)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Lithuania -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+370', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('LT')}">
                        <div class="flag lt"></div>
                        Lithuania (+370)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Philippines -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+63', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('PH')}">
                        <div class="flag ph"></div>
                        Philippines (+63)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Poland -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+48', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('PL')}">
                        <div class="flag pl"></div>
                        Poland (+48)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Portugal -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+351', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('PT')}">
                        <div class="flag pt"></div>
                        Portugal (+351)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Singapore -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+65', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('SG')}">
                        <div class="flag sg"></div>
                        Singapore (+65)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Slovakia -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+421', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('SK')}">
                        <div class="flag sk"></div>
                        Slovakia (+421)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Switzerland -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+41', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('CH')}">
                        <div class="flag ch"></div>
                        Switzerland (+41)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- Sweden -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+46', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('SE')}">
                        <div class="flag se"></div>
                        Sweden (+46)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                    <!-- United Kingdom -->
                    <li class="dropdown-option flex flex-row align-center"
                        @click="selectCountry('+44', index, user)"
                        :class="{'selected': dropdownValue == getFlagIcon('GB')}">
                        <div class="flag gb"></div>
                        United Kingdom (+44)
                        <div class="selected-icon">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
    mixins: [ clickaway ],
    data: () => ({
        showDropdown: false,
        selectedCountry: ""
    }),
    props: ['selectCountry', 'dropdownValue', 'index', 'user', 'disabled', 'invalid'],
    methods: {
        getFlagIcon(countryCode) {
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char =>  127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        },
        hideDropdown() {
            this.showDropdown = false;
        },
        clickHandler() {
            if (this.disabled) { return; }
            else {
                this.showDropdown = !this.showDropdown;
            }
        }
    }
}
</script>

<style scoped>
.area-code-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: inherit;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    width: 40px;
    height: 34px;
    cursor: pointer;
    color: #e1e3eb;
    font-weight: 400;
    border-radius: 4px;
    border: 2px solid #e1e3eb;
    transition: border .15s ease,
                background-color .15s ease,
                color .15s ease;
}
.area-code-dropdown.disabled {
    background: #f8f8f8;
    cursor: not-allowed;
}
.area-code-dropdown.invalid {
    border-color: #f87373;
}
.area-code-dropdown-wrapper {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    max-height: 280px;
    overflow-y: scroll;
    box-shadow: 0 3px 30px 0 #00000012;
    border-radius: 4px;
    z-index: 3;
}
.area-code-dropdown-wrapper .dropdown {
    width: 270px;
    color: var(--dark-default);
    list-style-type: none;
    padding: 5px;
    margin: 0;
    background-color: white;
}
.area-code-dropdown-wrapper .dropdown .dropdown-option {
    padding: 15px;
    border-radius: 4px;
}
.area-code-dropdown-wrapper .dropdown .dropdown-option:hover {
    cursor: pointer;
    background-color: #f6faff;
    color: #155ffc;
}
.area-code-dropdown-wrapper .dropdown .dropdown-option.selected {
    background-color: #f6faff;
    color: #155ffc;
}
.area-code-dropdown-wrapper .dropdown .dropdown-option .selected-icon {
    display: none;
    margin-left: auto;
    font-size: 18px;
    color: var(--brand-accent-blue);
}
.area-code-dropdown-wrapper .dropdown .dropdown-option.selected .selected-icon {
    display: block;
}
.area-code-dropdown-wrapper .dropdown .dropdown-option .flag {
    /* font-size: 20px; */
    margin-right: 1rem;
    float: left;
    padding: 0 !important;
    width: 16px;
    height: 11px;
    background: url('/images/flag-spritesheet.png') no-repeat;
}
.selected-flag {
    width: 16px;
    height: 11px;
    background: url('/images/flag-spritesheet.png') no-repeat;
}
/* Country Area Codes & Background Positions for Spritesheet */
.flag.none,
.selected-flag.none {
    background: none !important;
}
.flag.au,
.selected-flag.au {
    background-position: 0 -132px !important;
}
.flag.cl,
.selected-flag.cl {
    background-position: 0 -484px !important;
}
.flag.cz,
.selected-flag.cz {
    background-position: -16px -11px !important;
}
.flag.ee,
.selected-flag.ee {
    background-position: -16px -99px !important;
}
.flag.hk,
.selected-flag.hk {
    background-position: -16px -462px !important;
}
.flag.hu,
.selected-flag.hu {
    background-position: -16px -517px !important;
}
.flag.il,
.selected-flag.il {
    background-position: -16px -550px !important;
}
.flag.it,
.selected-flag.it {
    background-position: -32px -33px !important;
}
.flag.lt,
.selected-flag.lt {
    background-position: -32px -275px !important;
}
.flag.ph,
.selected-flag.ph {
    background-position: -48px -165px !important;
}
.flag.pl,
.selected-flag.pl {
    background-position: -48px -187px !important;
}
.flag.pt,
.selected-flag.pt {
    background-position: -48px -242px !important;
}
.flag.sg,
.selected-flag.sg {
    background-position: -48px -407px !important;
}
.flag.sk,
.selected-flag.sk {
    background-position: -48px -451px !important;
}
.flag.ch,
.selected-flag.ch {
    background-position: 0 -451px !important;
}
.flag.se,
.selected-flag.se {
    background-position: -48px -396px !important;
}
.flag.gb,
.selected-flag.gb {
    background-position: -16px -275px !important;
}
@media only screen and (min-width: 992px) {
    /* Transitions */
    .fade-slide-enter-active, .fade-slide-leave.active {
        transition: opacity .3s ease,
                    transform .3s ease;
    }
    .fade-slide-enter, .fade-slide-leave-to {
        opacity: 0;
        transform: translateY(20px);
    }
}
</style>
<!-- Test push -->